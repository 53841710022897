import { pickBy } from '@/utils'
import AttrCategory from './attr-category'
import ProductLabel from './product-label'

export default {
  name: 'nearbyShop',
  setting: [
    { label: '标题', key: 'title', component: 'input', value: '附近商家' },
    { label: '副标题', key: 'subtitle', component: 'input', value: '副标题' },
    { label: '组件间距', key: 'padded', component: 'switch', value: false },
    { label: '直接加购', key: 'addCart', component: 'switch', value: false },
    {
      label: '商家',
      key: 'seletedTags',
      component: function (h, { key }) {
        return <AttrCategory v-model={this.value[key]} />
      },
      value: []
    },
    {
      label: '商品标签',
      key: 'productLabel',
      component: function (h, { key }) {
        return <ProductLabel v-model={this.value[key]} />
      },
      value: []
    },
    {
      label: '导航展示',
      key: 'navigation_display',
      component: 'radio',
      options: [
        { name: '全部', label: 'all' },
        { name: '商家', label: 'business' },
        { name: '商品标签', label: 'productLabels' }
      ],
      value: 'all'
    },
    { label: '显示优惠券', key: 'show_coupon', component: 'switch', value: false },
    // { label: '显示更多附近商家', key: 'show_nearby_merchants', component: 'switch', value: false },
    // {
    //   label: '商家排序',
    //   key: 'shopSort',
    //   component: function (h, { key }) {
    //     return <div>{this.value[key]}</div>
    //   },
    //   value: 'LBS定位'
    // }
  ],
  transformIn: (v) => {
    const { name, base, seletedTags = [], productLabel = [] } = v
    return {
      name,
      ...base,
      seletedTags,
      productLabel
    }
  },
  transformOut: (v) => {
    return pickBy(v, {
      name: 'name',
      base: (v) => {
        return pickBy(v, {
          title: 'title',
          subtitle: 'subtitle',
          padded: 'padded',
          show_coupon: 'show_coupon',
          navigation_display:'navigation_display',
          addCart: 'addCart',
          // show_nearby_merchants:'show_nearby_merchants'
        })
      },
      seletedTags: 'seletedTags',
      productLabel: 'productLabel'
    })
  }
}
