var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"picker-tag"},[_c('SpFilterForm',{attrs:{"model":_vm.formData,"size":"small"},on:{"onSearch":_vm.onSearch,"onReset":_vm.onSearch}},[_c('SpFilterFormItem',{attrs:{"prop":"keywords"}},[_c('el-input',{attrs:{"placeholder":"请输入标签名称"},model:{value:(_vm.formData.keywords),callback:function ($$v) {_vm.$set(_vm.formData, "keywords", $$v)},expression:"formData.keywords"}})],1),(!_vm.IS_DISTRIBUTOR() && !_vm.values)?_c('SpFilterFormItem',{attrs:{"prop":"distributor_id","label":"店铺名称:"}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择"},model:{value:(_vm.formData.distributor_id),callback:function ($$v) {_vm.$set(_vm.formData, "distributor_id", $$v)},expression:"formData.distributor_id"}},_vm._l((_vm.salesStatus),function(item){return _c('el-option',{key:item.distributor_id,attrs:{"label":item.name,"size":"mini","value":item.distributor_id}})}),1)],1):_vm._e()],1),_c('SpFinder',{ref:"finder",attrs:{"other-config":{
      'max-height': 460,
      'header-cell-class-name': _vm.cellClass
    },"row-key":"tag_id","reserve-selection":"","url":"/goods/tag","fixed-row-action":true,"setting":{
      columns: [
        { name: 'ID', key: 'tag_id', width: 80 },
        { name: '标签名称', key: 'tag_name' },
        { name: '店铺名称', key: 'distributor_name' },
        { name: '描述', key: 'description' }
      ]
    },"hooks":{
      beforeSearch: _vm.beforeSearch,
      afterSearch: _vm.afterSearch
    }},on:{"select":_vm.onSelect,"selection-change":_vm.onSelectionChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }